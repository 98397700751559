






















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import type {
  MarkerItem,
} from '@/types';

@Component
export default class YandexMapWrapper extends Vue {
  @Prop({ type: Array, required: true })
  private readonly markers!: MarkerItem[]

  @Prop({ type: Boolean, default: false })
  public readonly hideTitle!: boolean;

  public get showAllMarkers(): boolean {
    return this.markers.length > 1;
  }

  public get centerCoords(): number[] {
    return this.markers[0].coords;
  }

  public getMarkerIcon(marker: MarkerItem): Record<string, string | number[]> {
    return {
      // layout: 'default#imageWithContent',
      // imageHref: 'https://image.flaticon.com/icons/png/512/33/33447.png',
      imageSize: [43, 43],
      imageOffset: [0, 0],
      content: `${marker.title}`,
      contentOffset: [0, 15],
      // contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>',
    };
  }
}
