






























import {
  Component,
  Prop,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
} from 'hooper';
import 'hooper/dist/hooper.css';
import objectFitImages from 'object-fit-images';

interface HooperCarousel {
  update: () => void
}

@Component({
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
})
export default class ImageCarousel extends Vue {
  @Prop({ type: Array, default: () => ([]) })
  public readonly images!: string[];

  @Ref() private readonly carousel!: HooperCarousel;

  public get carouselSettings(): Record<string, number | boolean> {
    return {
      infiniteScroll: true,
      trimWhiteSpace: true,
      centerMode: true,
      autoPlay: true,
      playSpeed: 3000,
    };
  }

  public get isShownPagination(): boolean {
    return this.images.length <= 20 || this.$mq !== 'sm';
  }

  public get carouselStyle(): Record<string, string> {
    return {
      height: this.$mq === 'lg' ? '700px' : '400px',
    };
  }

  @Watch('$mq', { immediate: true })
  private onBreakpointChanged(): void {
    if (this.carousel) {
      this.carousel.update();
    }
  }

  private mounted(): void {
    objectFitImages('img.image-carousel__content');
  }
}
